<template>
  <div class="freight-query-div">
    <div class="freight-query">
      <!-- 主区域 -->
      <div class="main">
        <div class="title">
          <img
            src="../assets/img/xiadanchenggong.svg"
            style="
              display: inline-block;
              vertical-align: middle;
              margin-top: -3px;
              margin-right: 12px;
            "
          />
          <span>订单提交成功，请选择支付方式</span>
        </div>
        <div class="content-center">
          <div class="content">
            <div class="cost">应付金额：</div>
            <div class="price">£{{ mountpayable }}</div>
          </div>
          <div class="content2">
            <div class="paymethod-select-div">
              <div
                class="recharge-item posi"
                :class="
                  selectPayType == payType.balance
                    ? 'balance-item-selected'
                    : ''
                "
                @click="payMethodClicked(0)"
              >
                <img
                  v-if="selectPayType == payType.balance"
                  src="../assets/img/yue1.svg"
                  style="vertical-align: middle"
                />
                <img
                  v-if="selectPayType == payType.bankcard"
                  src="../assets/img/yue2.svg"
                  style="vertical-align: middle"
                />
                <span class="costspace"
                  >余额（可用余额{{ myBalance }}英镑）</span
                >
                <img
                  class="select-icon"
                  v-if="selectPayType == payType.balance"
                  src="../assets/img/xuanzhong.svg"
                />
                <img
                  class="select-icon"
                  v-if="selectPayType != payType.balance"
                  src="../assets/img/weixuanzhong.svg"
                />
              </div>
              <div
                class="recharge-item posi"
                :class="
                  selectPayType == payType.bankcard
                    ? 'balance-item-selected'
                    : ''
                "
                @click="payMethodClicked(1)"
              >
                <img
                  v-if="selectPayType == payType.balance"
                  src="../assets/img/bankcard.svg"
                  style="vertical-align: middle"
                />
                <img
                  v-if="selectPayType == payType.bankcard"
                  src="../assets/img/bankcard-select.svg"
                  style="vertical-align: middle"
                />
                <span class="costspace">stripe支付</span>
                <img
                  v-if="selectPayType == payType.bankcard"
                  class="select-icon"
                  src="../assets/img/xuanzhong.svg"
                />
                <img
                  v-if="selectPayType != payType.bankcard"
                  class="select-icon"
                  src="../assets/img/weixuanzhong.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="pay-div" @click="payClicked()">立即支付</div>
        </div>

        <el-dialog
          :visible.sync="centerDialogVisible"
          width="30%"
          top="400px"
          :center="true"
        >
          <div class="tips">余额不足，请先</div>
          <div class="recharge-div" @click="rechargeClicked()">充值</div>
          <span slot="footer" class="dialog-footer"> </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {
  payOrderMoneyByRecharge,
  balancePayForOrderPackages,
  checkPaymentOrderStatus,
  updatePackageStatus,
} from "@/api/order/order";
import { getQueryVariable } from "@/utils/common";
import { getUserAmount } from "@/api/user/user";

export default {
  name: "FreightQuery",
  data() {
    return {
      //付款方式
      payType: {
        balance: 0,
        bankcard: 1,
      },
      //选择的支付方式  0:余额支付 1：银行卡支付
      selectPayType: 0,
      //余额不足提示
      centerDialogVisible: false,
      //应付金额
      mountpayable: this.$store.state.paymentObj.price || 0,
      ids: this.$store.state.paymentObj.ids,
      sn: this.$store.state.paymentObj.sn,
      discountCode: this.$store.state.paymentObj.discountCode,
      //余额
      myBalance: 10000,
      //订单支付id
      payId: this.$store.state.payId,
    };
  },
  created() {
    let flag = getQueryVariable("flag");
    if (flag == 0) {
      this.$message.error("已取消支付");
      let old_url = window.location.href;
      let new_url = old_url.substring(0, old_url.indexOf("?"));
      self.location = new_url; //1.当前直接替换了路径到url，实际上还是会进行跳转
      history.replaceState(null, null, new_url); //2.替换地址栏,直接将地址栏参数替换
    } else if (flag == 1) {
      this.checkPaymentOrderStatus();
      let old_url = window.location.href;
      let new_url = old_url.substring(0, old_url.indexOf("?"));
      self.location = new_url; //1.当前直接替换了路径到url，实际上还是会进行跳转
      history.replaceState(null, null, new_url); //2.替换地址栏,直接将地址栏参数替换
      // this.$message.success('支付成功')
      // this.$router.push({
      // 	path: `/ShoppingCart`,
      // })
    }
    this.getUserAmount();
  },
  methods: {
    //获取账户余额
    getUserAmount() {
      console.log("账户余额111");
      getUserAmount().then((res) => {
        if (res.data.code === 0) {
          this.myBalance = res.data.data.data.amount;
        }
        console.log(res, "账户余额");
      });
    },
    // 支付接口
    payOrderMoneyByRecharge(ids) {
      if(this.settlementLoading){
        return;
      }
      this.settlementLoading = true;
      let upData = {
        successPath: `${window.location.href}`,
        orderIds: ids,
        failPath: `${window.location.href}`,
      };
       //使用折扣码
      if (this.discountCode != "") {
        upData['discountCode'] = this.discountCode;
      }
      payOrderMoneyByRecharge(upData)
        .then((res) => {
          if (res.data.code === 0) {
            // updatePackageStatus("sn=" + JSON.stringify(this.sn)).then(
            //   (changeRes) => {
            //     console.log("changeRes ", changeRes);
            let url = res.data.data.toPath;
            this.$store.state.payId = res.data.data.payId;
            window.location.href = url;
            //   }
            // );
          } else {
            this.$message.error(res.data.message);
          }
          this.settlementLoading = false;
        })
        .catch(() => {
          this.settlementLoading = false;
          this.$message.error("支付失败");
        });
    },
    //余额支付
    async balancePayForOrderPackages(ids) {
      if(this.settlementLoading){
        return;
      }
      this.settlementLoading = true;
      let upData = {
        // successPath:`${window.location.href}`,
        orderIds: ids,
        // failPath: `${window.location.href}`
      };
      //使用折扣码
      if (this.discountCode != "") {
        upData['discountCode'] = this.discountCode;
      }
      console.log("upData", upData);
      balancePayForOrderPackages(upData)
        .then((res) => {
          console.log(res, "余额支付接口返回值");
          if (res.data.code === 0) {
            // updatePackageStatus("sn=" + JSON.stringify(this.sn)).then(
            //   (changeRes) => {
            //     console.log("changeRes ", changeRes);
            this.$message.success(res.data.message);
            this.$router.push({
              path: "/ShoppingCart",
            });
            //   }
            // );
          } else {
            this.$message.error(res.data.message);
          }
          this.settlementLoading = false;
        })
        .catch(() => {
          this.settlementLoading = false;
          this.$message.error("支付失败");
        });
    },
    //支付回调
    checkPaymentOrderStatus() {
      let upData = {
        id: this.payId,
      };
      checkPaymentOrderStatus(upData)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.message);
            this.$router.push({
              path: `/ShoppingCart`,
            });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch(() => {
          this.$message.error("支付失败");
        });
    },
    //选中 余额0   银行卡1
    payMethodClicked(arg) {
      this.selectPayType = arg;
    },
    //点击支付
    payClicked() {
      console.log("立即支付被点击");
      if (this.selectPayType === 1) {
        // 银行卡支付
        this.payOrderMoneyByRecharge(this.ids);
      } else if (this.selectPayType === 0) {
        // 余额支付
        console.log(this.mountpayable, "jiaqian");
        console.log(this.myBalance, "yue");
        if (this.mountpayable > this.myBalance) {
          this.centerDialogVisible = true;
        } else {
          console.log("余额充足");
          this.balancePayForOrderPackages(this.ids);
        }
      }
    },
    //点击充值按钮
    rechargeClicked() {
      console.log("充值按钮被点击");
      this.$router.push({
        path: "/MyAccount",
      });
      this.$store.state.curShowType = 1;
    },
  },
};
</script>

<style lang="less" scoped>
.freight-query-div {
  .freight-query {
    position: relative;
    background: #f4f4f4;
    height: 1080px;
    // 主区域
    .main {
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      //background: #f4f4f4;
      .title {
        height: 133px;
        width: 1000px;
        //padding-left: 30px;
        font-size: 24px;
        font-weight: 600;
        color: #1e2b4f;
        line-height: 133px;
        text-align: left;
      }
      .content-center {
        height: 385px;
        background: #fff;
        border-radius: 8px;
      }
      // 搜素结果
      .content {
        background: #fff;
        height: 85px;
        width: 1000px;
        border-radius: 8px;
        margin-bottom: 30px;
      }
      .cost {
        margin-left: 30px;
        height: 33px;
        font-size: 14px;
        font-weight: 600;
        color: #1e2b4f;
        line-height: 33px;
        float: left;
        width: 70px;
        margin-top: 40px;
        text-align: right;
      }
      .price {
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #ff6400;
        line-height: 33px;
        float: right;
        width: 890px;
        margin-top: 40px;
      }
      .costspace {
        margin-left: 15px;
      }
      .content2 {
        background: #fff;
        width: 1000px;
        height: 260px;
        border-radius: 8px;
      }

      .recharge-item {
        margin-left: 30px;
        margin-bottom: 20px;
        padding-left: 30px;
        position: relative;
        width: 580px;
        height: 100px;
        //border-radius: 8px;
        border: 1px solid #d2d5dc;
        color: #1e2b4f;
        line-height: 100px;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        .select-icon {
          position: absolute;
          width: 27px;
          bottom: -1px;
          right: -1px;
        }
      }
      .balance-item-selected {
        background: #fff7f1;
        //border-radius: 8px;
        border: 1px solid #ff6400;
        color: #ff6400;
      }
      .bottom {
        margin: 30px;
        background: #f4f4f4;
        width: 1000px;
      }
      .pay-div {
        text-align: center;
        float: right;
        width: 250px;
        height: 52px;
        border-radius: 26px;
        background: #ff6400;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        line-height: 48px;
      }
      .tips {
        margin-top: 5px;
        margin-left: 110px;
        margin-right: 10px;
        width: 168px;
        height: 33px;
        font-size: 24px;
        font-weight: 600;
        color: #1e2b4f;
        line-height: 33px;
        float: left;
      }
      .recharge-div {
        display: flex;
        padding-left: 35px;
        width: 100px;
        height: 44px;
        background: #6d5efe;
        border-radius: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        line-height: 44px;
      }
    }
  }
}
</style>
